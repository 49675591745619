// extracted by mini-css-extract-plugin
export var cArrow = "styles-module--c-arrow--4c646";
export var cFilterButton = "styles-module--c-filter-button--529c4";
export var cFilters = "styles-module--c-filters--cfa14";
export var cLabel = "styles-module--c-label--86d1e";
export var cNumber = "styles-module--c-number--1c9c1";
export var cPagination = "styles-module--c-pagination--90506";
export var cPaginationButton = "styles-module--c-pagination-button--09cbe";
export var cProjects = "styles-module--c-projects--d7162";
export var cTextWrapper = "styles-module--c-text-wrapper--36a7c";
export var cTileCol = "styles-module--c-tile-col--3cf29";