import React, { useEffect, useState, useContext } from "react";
import { graphql } from "gatsby";
import Seo from "src/components/Seo";
import Hero from "src/components/Hero";
import { renderElements } from "src/utils/elements";
import {
  Button,
  Container,
  FlexboxCol,
  FlexboxRow,
  Text,
} from "src/components/Base";
import Tile from "src/components/Tile";
import {
  cArrow,
  cFilterButton,
  cFilters,
  cLabel,
  cNumber,
  cPagination,
  cPaginationButton,
  cProjects,
  cTextWrapper,
  cTileCol,
} from "./styles.module.scss";
import classNames from "classnames";
import ArrowLeft from "src/assets/images/chevron-left.inline.svg";
import ArrowRight from "src/assets/images/chevron-right.inline.svg";
import LanguageContext from "src/context/LanguageContext";
import { rewriteProjectUrl } from "src/utils/language";

const ProjectOverviewTemplate = ({ data }) => {
  const itemsPerPage = 12;
  const page = data.wpPage;
  const projects = data.allWpProject.nodes;
  const elements = page.plakativPageElements.pageElements;
  const categories = data.allWpCategory.nodes;
  const translations =
    data.allWp.nodes[0].themeGeneralSettings.plakativStringTranslations;

  const [currentPage, setCurrentPage] = useState(0);
  const [currentFilter, setCurrentFilter] = useState();
  const [visibleProjects, setVisibleProjects] = useState([]);
  const [numPages, setNumPages] = useState();

  const languageContext = useContext(LanguageContext);

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    setNumPages(Math.ceil(filteredProjects().length / itemsPerPage));
    setCurrentPage(0);
  }, [currentFilter]);

  useEffect(() => {
    setVisibleProjects(
      filteredProjects().splice(currentPage * itemsPerPage, itemsPerPage)
    );
  }, [currentPage, currentFilter]);

  const filteredProjects = () => {
    let tmpProjects = [...projects];

    tmpProjects = tmpProjects.filter((project) => {
      return project.language.slug === languageContext.currentLanguage;
    });

    if (currentFilter) {
      return tmpProjects.filter((project) => {
        return project.categories.nodes.some(
          (category) => category.termTaxonomyId === currentFilter
        );
      });
    }

    return tmpProjects;
  };

  const renderFilters = () => {
    const elements = [];
    categories.forEach((category) => {
      if (category.termTaxonomyId !== 1 && category.termTaxonomyId !== 1770) {
        elements.push(
          <Button
            className={cFilterButton}
            onClick={() => setCurrentFilter(category.termTaxonomyId)}
          >
            {category.name}
          </Button>
        );
      }
    });
    elements.push(
      <Button className={cFilterButton} onClick={() => setCurrentFilter()}>
        {languageContext.currentLanguage === "en" ? "All" : "Alle"}
      </Button>
    );

    return elements;
  };

  const renderProjects = () => {
    const elements = [];

    visibleProjects.forEach((project) => {
      elements.push(
        <FlexboxCol className={cTileCol}>
          <Tile
            text={project.title}
            link={rewriteProjectUrl(
              project.link,
              languageContext.currentLanguage
            )}
            alt={project.featuredImage.node.altText}
            imageData={project.featuredImage.node.localFile.childImageSharp}
          />
        </FlexboxCol>
      );
    });

    return elements;
  };

  return (
    <>
      <Seo title={page.title} />
      <Hero heading={page.title} />
      {elements && renderElements(elements)}
      <Container className={cProjects}>
        <div className={cFilters}>{renderFilters()}</div>
        <FlexboxRow>{renderProjects()}</FlexboxRow>
        <div className={cPagination}>
          <Button
            className={cPaginationButton}
            onClick={prevPage}
            disabled={currentPage === 0}
          >
            <ArrowLeft className={cArrow} />
          </Button>
          <Text className={cTextWrapper}>
            <Text as="span" className={cLabel}>
              {languageContext.currentLanguage === "en"
                ? translations.stringTranslationPaginationPageEn
                : translations.stringTranslationPaginationPage}
            </Text>
            <Text as="span" className={classNames(cNumber, cLabel)}>
              {currentPage + 1}
            </Text>
            <Text as="span" className={cLabel}>
              {languageContext.currentLanguage === "en"
                ? translations.stringTranslationPaginationOfEn
                : translations.stringTranslationPaginationOf}
            </Text>
            <Text as="span" className={classNames(cNumber, cLabel)}>
              {numPages}
            </Text>
          </Text>
          <Button
            className={cPaginationButton}
            onClick={nextPage}
            disabled={currentPage >= numPages - 1}
          >
            <ArrowRight className={cArrow} />
          </Button>
        </div>
      </Container>
    </>
  );
};

export const pageQuery = graphql`
  query myProjectOverviewQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      plakativPageElements {
        ...PageElementsFragment
      }
    }
    allWp {
      nodes {
        themeGeneralSettings {
          plakativStringTranslations {
            stringTranslationPaginationPage
            stringTranslationPaginationOf
          }
        }
      }
    }
    allWpCategory {
      nodes {
        id
        name
        termTaxonomyId
      }
    }
    allWpProject(
      sort: {
        fields: [projectSettings___isInnsbruck, date]
        order: [ASC, DESC]
      }
    ) {
      nodes {
        categories {
          nodes {
            id
            name
            termTaxonomyId
          }
        }
        language {
          slug
        }
        title
        link
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
              }
            }
          }
        }
      }
    }
  }
`;
export default ProjectOverviewTemplate;
